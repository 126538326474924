<script setup>
import { onMounted, onBeforeMount, ref, reactive } from 'vue';
import { useRouter } from 'vue-router';
import useBillingRates from '../../../../hooks/useBillingRates';
import pageTitle, { setPageTitle } from '@/utils/pageTitle';
import { useToast } from "vue-toastification";

const rateId = ref(null)
const route = useRouter()
const toast = useToast()
const rateData = reactive({
    category: null,
    code: null,
    description: null,
    cost: null,
    our_cost: null,
    qty: null,
    quickbook: null
});

const { updateRates, rate, fetchRateWithId } = useBillingRates();

const props = defineProps({
    params: Object
});

onBeforeMount(() => {
    rateId.value = route.currentRoute.value.params.id
    fetchCurrentRateData(rateId.value)
})

onMounted(() => {
    setPageTitle("Edit Billing Rate");
})

const fetchCurrentRateData = async (id) => {

    await fetchRateWithId(id)

    if (rate) {
        rateData.category = rate.value.category
        rateData.code = rate.value.code
        rateData.description = rate.value.description
        rateData.cost = rate.value.cost
        rateData.our_cost = rate.value.our_cost
        rateData.qty = rate.value.qty
    }
}

const update = async () => {
    updateRates(rateId.value, rateData);
    toast.success("Rate Updated Successfully.");
    route.push('/billings/default-billing-rates');
}

</script>

<template>
    <v-card-title>{{ pageTitle }}</v-card-title>
    <v-card flat class="mx-4 mt-4">
        <v-card-text>
            <v-row align="center">
                <v-col cols="6">
                    <label for="category">Category</label>
                </v-col>
                <v-col cols="6">
                    <v-text-field density="compact" v-model="rateData.category" id="category" hide-details="auto"
                        name="category" variant="outlined"></v-text-field>
                </v-col>

                <v-divider />

                <v-col cols="6">
                    <label for="code">Code</label>
                </v-col>
                <v-col cols="6">
                    <v-text-field v-model="rateData.code" density="compact" id="code" hide-details="auto" name="code"
                        variant="outlined"></v-text-field>
                </v-col>

                <v-divider />

                <v-col cols="6">
                    <label>Description</label>
                </v-col>
                <v-col cols="6">
                    <v-text-field v-model="rateData.description" density="compact" id="description" hide-details="auto"
                        name="description" variant="outlined"></v-text-field>
                </v-col>

                <v-divider />

                <v-col cols="6">
                    <label>Fee</label>
                </v-col>
                <v-col cols="6">
                    <v-text-field v-model="rateData.cost" density="compact" id="cost" hide-details="auto" name="cost"
                        type="number" variant="outlined"></v-text-field>
                </v-col>

                <v-divider />

                <v-col cols="6">
                    <label>Our Cost</label>
                </v-col>
                <v-col cols="6">
                    <v-text-field v-model="rateData.our_cost" density="compact" id="our_cost" hide-details="auto"
                        type="number" name="our_cost" variant="outlined"></v-text-field>
                </v-col>

                <v-divider />

                <v-col cols="6">
                    <label>Quantity In Stock</label>
                </v-col>
                <v-col cols="6">
                    <v-text-field v-model="rateData.qty" density="compact" id="qty" hide-details="auto" name="qty"
                        type="number" variant="outlined"></v-text-field>
                </v-col>

                <v-col cols="6">
                    <label>Quick Book</label>
                </v-col>
                <v-col cols="6">
                    <v-select v-model="rateData.quickbook"
                        :items="[{ title: 'QuickBook 1', value: 'qb1' }, { title: 'QuickBook 2', value: 'qb1' }]" name="quickbooks"
                        density="compact" variant="outlined"></v-select>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>

    <v-card-actions class="d-flex justify-end mx-2 mt-4">
        <v-btn size="large" variant="flat" color="primary" @click="update()">
            Save
        </v-btn>
    </v-card-actions>
</template>